import { Plugin } from "@ckeditor/ckeditor5-core/src";
import {
  FileRepository,
  UploadAdapter,
  FileLoader,
} from "@ckeditor/ckeditor5-upload";
import ImageUploadEditing from "@ckeditor/ckeditor5-image/src/imageupload/imageuploadediting";

import * as OSS from "ali-oss";
import * as SparkMD5 from "spark-md5";

export default class OSSUploadAdapter extends Plugin {
  static get requires() {
    return [FileRepository, ImageUploadEditing];
  }

  static get pluginName() {
    return "OSSUploadAdapter";
  }

  init() {
    const fileRepository = this.editor.plugins.get(FileRepository);
    fileRepository.createUploadAdapter = (loader: FileLoader) =>
      new Adapter(loader);

    const imageUploadEditing = this.editor.plugins.get(ImageUploadEditing);
    imageUploadEditing.on("uploadComplete", () => {
      this.editor.commands.execute("resizeImage", { width: "100%" });
    });
  }
}

class Adapter implements UploadAdapter {
  private loader: FileLoader;
  private client: OSS;

  constructor(loader: FileLoader) {
    this.loader = loader;
    this.client = new OSS({
      region: "oss-cn-beijing",
      accessKeyId: "LTAI5tBZFGVsWwgTnFxREnRx",
      accessKeySecret: "wxn5vLnDU7cFDG5u8H8T1WNv3E129e",
      bucket: "hxonline-mtsp",
    });
  }

  async calcMd5(file: File): Promise<string> {
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      const Spark = new SparkMD5.ArrayBuffer();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function (e) {
        Spark.append(e.target!.result as ArrayBuffer);
        const md5 = Spark.end();
        resolve(md5);
      };
    });
  }

  async upload(): Promise<Record<string, unknown>> {
    const file = await this.loader.file;
    if (!file) {
      return Promise.reject(new Error("File is not defined."));
    }
    const fileExtension = file.name
      .substring(file.name.lastIndexOf(".") + 1, file.name.length)
      .toLowerCase();
    const identifier = await this.calcMd5(file);
    const result = await this.client.put(
      `common/${identifier}.${fileExtension}`,
      file
    );
    return {
      default: result.url,
    };
  }

  abort() {}
}
