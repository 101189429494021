export function isSupported(option) {
  return /^\d(.\d+)?$/gm.test(String(option));
}

export function normalizeOptions(configuredOptions) {
  return configuredOptions.map(optionDefinition).filter((option) => !!option);
}

export function buildDefinition(options) {
  const definition = {
    model: {
      key: "lineHeight",
      values: options.slice(),
    },
    view: {},
  };

  for (const option of options) {
    definition.view[option] = {
      key: "style",
      value: {
        "line-height": `${(option * 1.6).toFixed(2)}em`,
      },
    };
  }

  return definition;
}

function optionDefinition(option) {
  if (typeof option === "object") {
    return option;
  }

  if (option === "default") {
    return {
      model: undefined,
      title: "Default",
    };
  }

  const sizePreset = parseFloat(option);

  if (isNaN(sizePreset)) {
    return;
  }

  return generatePixelPreset(sizePreset);
}

function generatePixelPreset(size) {
  const sizeName = String(size);

  return {
    title: `${sizeName}倍行距`,
    model: size,
    view: {
      name: "span",
      styles: {
        "line-height": `${(size * 1.6).toFixed(2)}em`,
      },
      priority: 5,
    },
  };
}
